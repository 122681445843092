import React from "react";

function About() {
  return (
  <div className="site-wrapper site-bg site">
    <div className="site-body">
      <section className="about-me-section topSpace">
        <div className="col-12 p-1 p-sm-3">
          <h1 className="left">About me:</h1>
        </div>
        <div className="col-md-12">
          <p className="left">
          I am a Data Delivery Analyst with certification in software development.
          </p>
          <p className="left">
            Directly after receiving my certification in software development from the Innovate Birmingham program, I began my career as a Systems Reporting Associate with my current company - eventually moving up to become a Data Delivery Analyst. My main objective is to create several projects showcasing my development ability in order to secure a position doing what I am most passionate about.
          </p>
        </div>
      </section>
      <hr />
      <section class="bottomSpace topSpace">
        <h1>Skills</h1>
        <div class="container topSpace">
          <div class="row">
            <div class="col-sm skills">
              JavaScript
            </div>
            <div class="col-sm skills">
              JQuery
            </div>
            <div class="col-sm skills">
              React
            </div>
            <div class="col-sm skills">
              T-SQL
            </div>
          </div>
        </div>
        <div class="container topSpace">
          <div class="row">
            <div class="col-sm skills">
              HTML
            </div>
            <div class="col-sm skills">
              CSS
            </div>
            <div class="col-sm skills">
              Data Structures
            </div>
            <div class="col-sm skills">
              NodeJS
            </div>
          </div>
        </div>
        <div class="container topSpace">
          <div class="row">
            <div class="col-sm skills">
              SSMS
            </div>
            <div class="col-sm skills">
              Powershell
            </div>
            <div class="col-sm skills">
              Visual Studio
            </div>
            <div class="col-sm skills">
              Jams
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  );
}

export default About;